import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CircleIcon from '@mui/icons-material/Circle';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PaymentModal from 'components/PaymentModal/PaymentModal';
import { auth } from "api/firebase";
import {
    enterQueue, 
    getTaxisInParkingSpots,
    getTaxisInQueue, 
    leaveParkingSpot,
    leaveQueue,
    saveParkingQueueEvent,
    pushToTopOfQueue
} from 'api/endpoints/parkingQueue';
import { createPaymentIntent } from 'api/endpoints/payments'
import Modal from 'components/Modal/Modal';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { green, red, transparentLightBlue } from 'styles/colors';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { convertToTime } from 'utils/timeConversions';
import { signin } from 'api/endpoints/auth';
import { isInZone } from 'utils/zoning';

const useStyles = makeStyles({
    loadingContainer:{
      justifyContent: "center",
      display: "flex",
      padding: 10,
      alignSelf: "center"
    },
    queueContainer:{
        overflow: "auto",
        height: 300
    }
});

const stripePromise = loadStripe(
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_STRIPE_DEV_KEY
        : process.env.REACT_APP_STRIPE_PROD_KEY
);

const oneMinute = 60

const ParkingSpotsAndQueueSideBar = (props) => {
    const { user, airport } = props;
    const classes = useStyles();
    const [isQueueStatusLoading, setIsQueueStatusLoading] = useState(false)
    const [occupiedParkingSpaces, setOccupiedParkingSpaces] = useState(null)
    const [emptyParkingSpaces, setEmptyParkingSpaces] = useState(null)
    const [open, setModalOpen] = useState(false);
    const [userIDInParkingSpace, setUserIDInParkingSpace] = useState(null)
    const [clientSecret, setClientSecret] = useState(null);
    const [paymentIntentId, setPaymentIntentId] = useState(null);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [openLeaveParkingSpotModal, setLeaveParkingSpotModalOpen] = useState(false);
    const [openLeaveQueueModal, setLeaveQueueModalOpen] = useState(false);

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };

    const { data: currentUser } = useQuery(
        ['currentUser'],
        async () => {
            const getUserInfo = await signin();
            return getUserInfo.data.data[0]
        },
        {
            refetchInterval: 500,
            refetchIntervalInBackground: true,
            cacheTime: 0
        }
    )

    const { data: taxisInParkingSpots } = useQuery(
        ['getTaxisInParkingSpots'],
        async () => {
            const response = await getTaxisInParkingSpots();
            let taxis = response.data.data;
            
            const countEmptySpots = airport.parkingSpots - taxis.length;
            setOccupiedParkingSpaces(taxis.length)
            setEmptyParkingSpaces(countEmptySpots)
            for (let i = 0; i < countEmptySpots; i++){
                taxis.push({})
            }
            
            return taxis
        },
        {
            refetchInterval: 500,
            refetchIntervalInBackground: true,
            cacheTime: 0
        }
    )

    const { data: taxisInQueue, isFetching: isFetchingTaxisInQueue } = useQuery(
        ['getTaxisInQueue'],
        async () => {
            const taxis = await getTaxisInQueue()
            return taxis.data.data
        },
        {
          refetchInterval: 500,
          refetchIntervalInBackground: true,
          cacheTime: 0
        },
    )

    const handleEnterQueue = async() => {
        setIsQueueStatusLoading(true)
        try{
            if (airport?.hidePayment == true || ~~(user.accountBalance/airport.taxiFee) > 0){
                await Promise.all([
                    enterQueue(),
                    saveParkingQueueEvent({
                        uid: auth.currentUser?.uid,
                        airportCode: user.airportCode,
                        firstAndLastName: user.firstAndLastName,
                        companyName: user.companyName,
                        taxiNumber: user.taxiNumber,
                        event: "Entered Queue",
                    })
                ]);
                setIsQueueStatusLoading(false)
            }
            else{
                const paymentIntentResponse = await createPaymentIntent(user.firstAndLastName)
                setPaymentIntentId(paymentIntentResponse.data.data.id)
                setClientSecret(paymentIntentResponse.data.data.clientSecret)
                setPaymentModalOpen(true)
            }
        }catch(e){
            console.log(e)
            setIsQueueStatusLoading(false)
        }
    }

    const handleLeaveQueue = async() => {
        setIsQueueStatusLoading(true)
        try{
            await Promise.all([
                leaveQueue(),
                saveParkingQueueEvent({
                    uid: auth.currentUser?.uid,
                    airportCode: user.airportCode,
                    firstAndLastName: user.firstAndLastName,
                    companyName: user.companyName,
                    taxiNumber: user.taxiNumber,
                    event: "Exited Queue",
                })
            ]);
            setIsQueueStatusLoading(false)
        }catch(e){
            console.log(e)
            setIsQueueStatusLoading(false)
        }
    }

    const handleLeaveParkingSpot = async() => {
        setIsQueueStatusLoading(true)
        try{
            await Promise.all([
                leaveParkingSpot(user?.uid),
                saveParkingQueueEvent({
                    uid: user.uid,
                    airportCode: user.airportCode,
                    firstAndLastName: user.firstAndLastName,
                    companyName: user.companyName,
                    taxiNumber: user.taxiNumber,
                    event: "Exited Parking Spot from Leave Parking Spot Button",
                })
            ]);
            setIsQueueStatusLoading(false)
        }catch(e){
            console.log(e)
            setIsQueueStatusLoading(false)
        }
    }

    const removeTaxiFromParkingSpot = async() => {
        try{
            await leaveParkingSpot(userIDInParkingSpace)
        }catch(e){
            console.log(e)
        }
    }

    const handleModalOpen = (userID) => {
        setUserIDInParkingSpace(userID)
        setModalOpen(true);
    };

    const handleLeaveParkingSpotModalOpen = () => {
        setLeaveParkingSpotModalOpen(true);
    };

    const handleLeaveQueueModalOpen = () => {
        setLeaveQueueModalOpen(true);
    };

    const detectIfInStagingArea = (lat, lng) => {
        return !!airport?.stagingArea && isInZone(lat, lng, airport?.stagingArea)
            ? ", IN STAGING AREA"
            : "";
    }

    const handlePushToTopOfQueue = (uid) => () => {
        pushToTopOfQueue(uid);
        alert("Taxi has been moved to the top of the queue.")
    };
    
    return (
        <Paper sx={{display: {xs: "block", sm: 'flex', md: "block", lg: "block"}}}>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <PaymentModal
                        paymentModalOpen = {paymentModalOpen}
                        setPaymentModalOpen = {setPaymentModalOpen}
                        paymentIntentId = {paymentIntentId}
                        enterQueue = {enterQueue}
                        setIsQueueStatusLoading = {setIsQueueStatusLoading}
                        saveParkingQueueEvent = {saveParkingQueueEvent}
                    />
                </Elements>
            )}
            <Modal
                popupTitle = "Are you sure you want to remove this taxi from the parking spot?" 
                popupDescription = "If this taxi wants to be added back to the parking spot, the taxi will have to be added to the queue."
                agreeAction={removeTaxiFromParkingSpot}
                open={open}
                setOpen={setModalOpen}
            />
            <Modal
                popupTitle="Are you sure you want to remove yourself from the parking spot?" 
                popupDescription="Once removed, you will have to re-enter the queue."
                agreeAction={handleLeaveParkingSpot}
                open={openLeaveParkingSpotModal}
                setOpen={setLeaveParkingSpotModalOpen}
            />
            <Modal
                popupTitle = "Are you sure you want to remove yourself from the queue?" 
                popupDescription = "Once removed, you will have to re-enter the queue."
                agreeAction={handleLeaveQueue}
                open={openLeaveQueueModal}
                setOpen={setLeaveQueueModalOpen}
            />
            <List>
                <ListItem>
                    <Typography variant="h6">Parking Spots</Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon fontSize='small' style = {{color: red}}/>{occupiedParkingSpaces} Reserved 
                </ListItem>
                <ListItem>
                    <CircleIcon fontSize='small' style = {{color: green}}/>{emptyParkingSpaces} Empty
                </ListItem>
                <ListItem>
                    <CircleIcon fontSize='small' style={{ color: transparentLightBlue}} /> Entered Parking Zone
                </ListItem>
                <ListItem>
                    <CircleIcon fontSize='small' style={{ color: 'orange'}} /> En Route
                </ListItem>
                {taxisInParkingSpots?.length ? 
                    taxisInParkingSpots.map((data, i) => {
                        return(
                            <ListItem key = {i} alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar style = {{background: "white"}}>
                                        <LocalTaxiIcon 
                                            fontSize='large' 
                                            style = {{
                                                color: 
                                                    data?.inParkingZone ? transparentLightBlue :
                                                    Number((Date.now()/1000).toFixed()) - data.lastTimeLocationWasUpdated < oneMinute  ? "orange" :
                                                    data?.inParkingSpot ? red : green
                                            }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={data?.taxiNumber ? data?.taxiNumber : "___________"}
                                    secondary={
                                        data?.taxiNumber ? 
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {data.companyName}
                                                </Typography>
                                                {` - ${data.firstAndLastName}`}
                                                {` - ${convertToTime(data.timeAssignedToParkingSpot)}`}
                                            </React.Fragment>
                                        : null
                                    }
                                />
                                {!user?.taxiNumber && data?.taxiNumber?
                                    <Button 
                                        variant="outlined" 
                                        color="error"
                                        onClick={() => {
                                            handleModalOpen(data.uid)
                                        }}
                                    >
                                        Remove
                                    </Button>
                                : null}
                            </ListItem>
                        )
                    }) 
                : <div className={classes.loadingContainer}> <CircularProgress/> </div>}
            </List>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem><Typography variant="subtitle2">{taxisInQueue?.length ? taxisInQueue?.length : 0} taxis are in the queue</Typography></ListItem>
                {isQueueStatusLoading ? 
                    <div className={classes.loadingContainer}> <CircularProgress/> </div> : 
                currentUser?.taxiNumber && ( 
                    <>
                        {!currentUser?.inQueue && !currentUser?.inParkingSpot && !isQueueStatusLoading ?
                            <ListItem>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    type="submit"
                                    onClick={handleEnterQueue}
                                >
                                    Enter Queue
                                </Button>
                            </ListItem>
                        :currentUser?.inParkingSpot && !isQueueStatusLoading ?
                            <ListItem>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="error"
                                    type="submit"
                                    onClick={airport?.leaveQueueModal ? handleLeaveParkingSpotModalOpen : handleLeaveParkingSpot}
                                >
                                    Leave Parking Spot
                                </Button>
                            </ListItem>
                        :currentUser?.inQueue && !isQueueStatusLoading && (
                            <ListItem>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="error"
                                    type="submit"
                                    onClick={airport?.leaveQueueModal ? handleLeaveQueueModalOpen : handleLeaveQueue}
                                >
                                    Leave Queue
                                </Button>
                            </ListItem>
                        )}
                    </>
                )}
                
                <div className={classes.queueContainer} style = {{height: !taxisInQueue?.length ? 0 : taxisInQueue?.length > 0 && taxisInQueue?.length < 3 ? 200 : 300}}>
                    {isFetchingTaxisInQueue && taxisInQueue?.length < 1 ? 
                        <div className={classes.loadingContainer}> <CircularProgress/> </div> 
                    : taxisInQueue?.length > 0 ? 
                        taxisInQueue.map((data, i) => {
                            return(
                                <div key = {i}>
                                    <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <ListItemText
                                            primary={`${i + 1}. ${data.taxiNumber}`}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {data.companyName}
                                                    </Typography>
                                                    {` - ${data.firstAndLastName}${detectIfInStagingArea(data.lat, data.lng)}`}
                                                </React.Fragment>
                                            }
                                        />
                                        {!user?.taxiNumber && !!airport?.stagingArea && isInZone(data.lat, data.lng, airport?.stagingArea) && (
                                            <Button 
                                                variant="outlined" 
                                                onClick={handlePushToTopOfQueue(data.uid)}
                                                color="info"
                                                sx={{ mt: 1 }}
                                            >
                                                Move to the Top
                                            </Button>
                                        )}
                                    </ListItem>
                                    <Divider component="li" />
                                </div>
                            )
                        }) 
                    : null}
                </div>
            </List>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return{
        user: state.userReducer.user,
        airport: state.airportReducer.airport
    }
}

export default connect(mapStateToProps)(ParkingSpotsAndQueueSideBar)