import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { signin } from 'api/endpoints/auth';
import { auth } from "api/firebase";
import Header from "components/Header/Header";
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Logo from "components/Logo/Logo";
import airportAccess from 'constants/airportAccess.json';
import healthcareAccess from 'constants/healthcareAccess.json';
import airportStats from "constants/airportStats.json";
import adminAirportTabs from "constants/adminAirportTabs.json";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Route, useHistory, useLocation } from "react-router-dom";
import { updateAirport, updateAirports } from 'store/airport/actions';
import { updateUser } from 'store/user/actions';
import { darkerBlue, fadedDarkerBlue, greyishBlue } from "styles/colors";
import TabRoutes from './TabRoutes';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  tab: {
    padding: 15
  },
  listItemIcon: {
    minWidth: 35,
    maxWidth: 35, // Ensures the width remains fixed
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: theme.mixins.toolbar,
  tabSection : {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    overflow: "auto",
    paddingBottom: 10,
    flex: 1
  },
}));

const Dashboard = ({ user, updateUser, updateAirport, updateAirports, window, airport }) => {
  const classes = useStyles();
  const [loadScreen, changeLoadingScreen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory()
  const location = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const routes = useMemo(() => {
    return TabRoutes.map((prop, key) => {
      return(
        <Route key={key} path={`/dashboard${prop.path}`} component={prop.component} />
      )
    })
  }, [])

  useEffect(() => {
    if(user?.airportCode){
      if(user?.admin && airport?.code){
        history.push(`/dashboard${adminAirportTabs[airport.code][0]}`)
      }else{
        const firstTabAvailable = airportAccess[user?.airportCode][user?.taxiNumber || user?.guestServices ? "taxi" : "personnel"][0]
        history.push(`/dashboard${firstTabAvailable}`)
      }
    }else if (user?.healthcareProvider){
      const firstTabAvailable = healthcareAccess[user?.healthcareProvider]["personnel"][0]
      history.push(`/dashboard${firstTabAvailable}`)
    }
  }, [user, airport]) // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = useMemo(() => {
    const generateTab = (key, prop, isCurrentTab) => (
      <ListItem
        key={key}
        onClick={() => history.push(`/dashboard${prop.path}`)}
        style={{
          backgroundColor: isCurrentTab ? fadedDarkerBlue : "inherit",
          cursor: "pointer",
        }}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <prop.icon style={{ color: isCurrentTab ? "white" : darkerBlue }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              style={{
                fontSize: "0.875rem",
                color: isCurrentTab ? "white" : darkerBlue,
              }}
            >
              {prop.sidebarName}
            </Typography>
          }
        />
      </ListItem>
    );
  
    return TabRoutes.map((prop, key) => {
      const isCurrentTab = location.pathname === `/dashboard${prop.path}`;
      const accessLevel = user?.taxiNumber || user?.guestServices ? "taxi" : "personnel";

      if (user?.admin && adminAirportTabs[airport.code]?.includes(prop.path)) {
        return generateTab(key, prop, isCurrentTab);
      }else if (airportAccess[user?.airportCode]?.[accessLevel]?.includes(prop.path)) {
        return generateTab(key, prop, isCurrentTab);
      }else if(healthcareAccess[user?.healthcareProvider]?.[accessLevel]?.includes(prop.path)){
        return generateTab(key, prop, isCurrentTab);
      }else{
        return null
      }
    });
  }, [location, history, airport, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const windowContainer = window !== undefined ? () => window().document.body : undefined;

  const drawerMenu = (
    <div style = {{borderRight: darkerBlue}}>
      <Toolbar style = {{background: darkerBlue}}>
        <Logo/>
      </Toolbar>
      <List style = {{padding: 0}}>
        {tabs}
      </List>
    </div>
  );

  useEffect(() => {
    onAuthStateChanged(auth, async(firebaseUser) => {
      if(firebaseUser) {
        try{
          const getUserInfo = await signin();
          const userInfo = getUserInfo.data.data[0];
          if(userInfo){
            updateAirport(airportStats.filter((airport) => airport.code === userInfo.airportCode)[0])
            updateAirports(airportStats)
            updateUser(userInfo)
          }
          changeLoadingScreen(false)
        }catch(e){
          console.log(e)
          changeLoadingScreen(false)
        }
      }else{
        history.push('/auth')
      }
    });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  if(loadScreen){
    return(
      <LoadingScreen/>
    )
  }
  return (
    <Box sx={{ display: 'flex', height: "inherit", background: greyishBlue }}>
      <Header drawerOpen = {drawerOpen} handleDrawerToggle = {handleDrawerToggle}/>
      <Box 
        component="nav" 
        sx={{ 
          "@media (min-width: 900px)":{
            width: drawerWidth,
          }
        }}
      >
        <Drawer
          container={windowContainer}
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              border: "none"
            }
          }}
        >
          {drawerMenu}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              border: "none"
            },
          }}
          open
        >
          {drawerMenu}
        </Drawer>
      </Box>
      <Box className={classes.tabSection} component="main">
        <Toolbar/>
        {routes}
      </Box>
    </Box>
  );
}

const mapDispatchToProps = {
  updateAirport, 
  updateAirports,
  updateUser
}

const mapStateToProps = (state) =>{
  return{
    airport: state.airportReducer.airport,
    user: state.userReducer.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)