import React, { useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import StatisticCard from 'components/StatisticCard/StatisticCard';
import Chart from 'react-apexcharts';
import { Line } from 'react-chartjs-2';
import { lightGrey } from "styles/colors";
import ReactEcharts from 'echarts-for-react';
import { useTheme } from '@mui/material';
import { green, yellow, red } from '@mui/material/colors';

const priorityColors = {
    Low: green[500],     // "success"
    Medium: yellow[700], // "warning"
    High: red[700],      // "error"
    Urgent: red[900]     // "error" (darker red for urgency)
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DoughnutChart = ({ height, color = [] }) => {
    const theme = useTheme()

    const option = {
        legend: {
            show: true,
            itemGap: 20,
            icon: 'circle',
            bottom: 0,
            textStyle: {
                color: theme.palette.text.secondary,
                fontSize: 13,
                fontFamily: 'roboto',
            },
        },
        tooltip: {
            show: false,
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        xAxis: [
            {
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
            },
        ],
        yAxis: [
            {
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
            },
        ],

        series: [
            {
                name: 'Live Ticket Priorities',
                type: 'pie',
                radius: ['45%', '72.55%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                hoverOffset: 5,
                stillShowZeroSum: false,
                label: {
                    normal: {
                      show: false,
                      position: 'center',
                      textStyle: {
                        color: theme.palette.text.secondary,
                        fontSize: 13,
                        fontFamily: 'roboto',
                      },
                      formatter: '{a}',
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '14',
                            fontWeight: 'normal',
                        },
                        formatter: '{b} \n{c} ({d}%)',
                    },
                },
                labelLine: {
                    normal: {
                        show: false,
                    },
                },
                data: [
                    {
                      value: 20,
                      name: 'Low',
                    },
                    { 
                      value: 25, 
                      name: 'Medium'
                    },
                    { 
                      value: 30, 
                      name: 'High'
                    },
                    { 
                      value: 25, 
                      name: 'Urgent'
                    },
                ],
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    }

    return (
        <ReactEcharts
            style={{ height: height }}
            option={{
                ...option,
                color: [...color],
            }}
        />
    )
}

const generateChartData = () => {
  const labels = ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7']; // 7-day labels

  return {
    labels,
    datasets: [
      {
        label: 'Incident',
        data: [150, 200, 250, 300, 270, 320, 350],
        borderColor: '#FFA500',
        backgroundColor: '#FFA500',
      },
      {
        label: 'Service Request',
        data: [100, 120, 140, 150, 180, 210, 220],
        borderColor: '#FFD700',
        backgroundColor: '#FFD700',
      },
      {
        label: 'Change Request',
        data: [50, 60, 90, 70, 80, 100, 120],
        borderColor: '#FF8C00',
        backgroundColor: '#FF8C00',
      },
      {
        label: 'Compliance and Security',
        data: [30, 40, 50, 60, 50, 70, 80],
        borderColor: '#FF4500',
        backgroundColor: '#FF4500',
      },
    ]
  };
};

const chartOptions = {
  chart: {
    type: 'bar',
    stacked: true,
    toolbar: {
      show: true
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  yaxis: {
    title: {
      text: 'Number of Tickets',
    }
  },
  legend: {
    position: 'bottom',
    offsetY: 0
  },
  fill: {
    opacity: 1
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#FFA500', '#FFD700', '#FF8C00', '#FF4500']
};

const chartSeries = [
  {
    name: 'Incident',
    data: [50, 80, 60, 100, 120, 150, 200, 170, 190, 220, 240, 210]
  },
  {
    name: 'Service Request',
    data: [30, 50, 40, 60, 80, 100, 120, 110, 130, 140, 150, 130]
  },
  {
    name: 'Change Request',
    data: [10, 20, 15, 30, 40, 60, 80, 70, 50, 60, 70, 50]
  },
  {
    name: 'Compliance and Security',
    data: [5, 10, 8, 15, 20, 25, 30, 25, 35, 40, 45, 30]
  }
];

const TicketReportsTab = () => {
  const lineChartData = generateChartData();

  return (
    <Grid container spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12} sm={12} md={6} spacing={3}>
        <StatisticCard
          title="Tickets Created This Month"
          count={230}
          textColor={lightGrey}
          backgroundColor="#fff"
          backgroundAfterColor="#FFD700"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} spacing={3}>
        <StatisticCard
          title="Tickets Created All-Time"
          count={96840}
          textColor={lightGrey}
          backgroundColor="#fff"
          backgroundAfterColor="#FF4500"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom sx={{color: lightGrey}}>
            Ticket Types
          </Typography>
          <Chart options={chartOptions} series={chartSeries} type="bar" height={400} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom sx={{color: lightGrey}}>
            Ticket Types Created Over Time
          </Typography>
          <Line data={lineChartData} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom sx={{color: lightGrey}}>
            Live Ticket Priorities
          </Typography>
          <DoughnutChart
            height="300px"
            color={[
              priorityColors.Low, priorityColors.Medium, priorityColors.High, priorityColors.Urgent
            ]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TicketReportsTab;
