import React, { useState } from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { lightGrey, UnDelayLogoBlue } from "styles/colors";
import StatisticCard from 'components/StatisticCard/StatisticCard';
import TicketTable from 'components/TicketTable/TicketTable';
import CreateTicketModal from 'components/modals/CreateTicketModal/CreateTicketModal';
import TicketDetails from 'components/TicketDetails/TicketDetails';

const TicketsTab = () => {
    const [ticketModalOpen, setTicketModalOpen] = useState(false);

    const handleTicketModalClose = () => {
        setTicketModalOpen(false);
    }

    return (
        <Grid container spacing={3} style={{ padding: 20 }}>
            <Grid item xs={12}>
                <Paper elevation={3} style={{ padding: 20, textAlign: 'center', display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4" align="center" sx={{color: lightGrey}}>
                    Tickets
                </Typography>
                <Button
                    onClick={() => setTicketModalOpen(true)}
                    variant="contained"
                    sx={{
                    backgroundColor: UnDelayLogoBlue,
                    color: 'white',
                    borderRadius: '8px',
                    padding: '10px 20px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                        '&:hover': { backgroundColor: "rgb(33 120 213)" } 
                    }}
                    startIcon={<AddIcon />} 
                >
                    Create Ticket
                </Button>
                </Paper>
            </Grid>

            {ticketModalOpen && (
                <CreateTicketModal
                    open={ticketModalOpen}
                    handleClose={handleTicketModalClose}
                />
            )}

            <Grid item xs={12}>
                <TicketTable/>
            </Grid>

            {/* <Grid item xs={12}>
                <TicketDetails/>
            </Grid> */}

            <Grid item xs={12} sm={12} md={3} spacing={3}>
                <StatisticCard
                    title="Unresolved"
                    count={130}
                    textColor={lightGrey}
                    backgroundColor="#fff"
                    backgroundAfterColor="#FFD700"
                    smallCircles={true}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={3} spacing={3}>
                <StatisticCard
                    title="Open"
                    count={115}
                    textColor={lightGrey}
                    backgroundColor="#fff"
                    backgroundAfterColor="#FF4500"
                    smallCircles={true}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={3} spacing={3}>
                <StatisticCard
                title="Pending"
                count={2}
                textColor={lightGrey}
                backgroundColor="#fff"
                backgroundAfterColor="#FFD700"
                smallCircles={true}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={3} spacing={3}>
                <StatisticCard
                    title="Resolved"
                    count={5}
                    textColor={lightGrey}
                    backgroundColor="#fff"
                    backgroundAfterColor="#FF4500"
                    smallCircles={true}
                />
            </Grid>
        </Grid>
    );
};

export default TicketsTab;
