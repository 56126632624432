import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from "react";
import { changeTaxiInformation, searchTaxis } from 'api/endpoints/parkingQueue';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const styles = {
    loadingSpinnerContainer:{
        display: "flex",
        justifyContent: "center",
        margin: 10
    }
}

const TaxiChangeInformationModal = ({ modalOpen, setModalOpen }) => {
    const [taxis, setTaxis] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let mounted = true;

        const fetchResults = async () => {
            setIsLoading(true);
            try {
                const taxisSearched = await searchTaxis(searchText);
                if (!mounted) return;
                setTaxis(taxisSearched.data.taxis);
            } catch (error) {
                console.error("Failed to fetch taxis:", error);
            } finally {
                if (mounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchResults()
        return () => {
            mounted = false
        }
    }, [searchText])

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleTaxiSearch = event => {
        const val = event.target.value;
        setSearchText(val);
    };

    const handleFirstAndLastNameChange = (uid) => (event) => {
        const { value } = event.target;

        setTaxis((prevTaxis) =>
            prevTaxis.map((taxi) =>
                taxi.uid === uid ? { ...taxi, firstAndLastName: value } : taxi
            )
        );

        changeTaxiInformation({ uid, firstAndLastName: value });
    };
    
    const handleTaxiNumberChange = (uid) => (event) => {
        const { value } = event.target;

        setTaxis((prevTaxis) =>
            prevTaxis.map((taxi) =>
                taxi.uid === uid ? { ...taxi, taxiNumber: value } : taxi
            )
        );

        changeTaxiInformation({ uid, taxiNumber: value });
    };
    
    const handleTaxiCompanyNameChange = (uid) => (event) => {
        const { value } = event.target;
        
        setTaxis((prevTaxis) =>
            prevTaxis.map((taxi) =>
                taxi.uid === uid ? { ...taxi, companyName: value } : taxi
            )
        );

        changeTaxiInformation({ uid, companyName: value });
    };

    return (
        <Dialog
            disableEnforceFocus
            open={modalOpen}
            onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { maxWidth: "700px" } }}
        >
            <DialogTitle>Taxi Information</DialogTitle>

            <DialogContent sx={{ width: { xs: 300, sm: 650 }}}>
                <TextField
                    label="Search name or taxi company"
                    onChange={handleTaxiSearch}
                    fullWidth
                    variant="standard"
                    sx={{mb: 2}}
                />
                {isLoading ? (
                    <Box sx={styles.loadingSpinnerContainer}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ padding: 2 }}>
                        <Grid container spacing={2}>
                            {taxis.map((taxi, i) => (
                                <Grid container item spacing={2} key={i}>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            label="First and Last Name"
                                            onChange={handleFirstAndLastNameChange(taxi.uid)}
                                            value={taxi.firstAndLastName}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            label="Taxi Number"
                                            onChange={handleTaxiNumberChange(taxi.uid)}
                                            value={taxi.taxiNumber}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            label="Company Name"
                                            onChange={handleTaxiCompanyNameChange(taxi.uid)}
                                            value={taxi.companyName}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Done</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TaxiChangeInformationModal;