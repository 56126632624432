import React, { useState } from 'react';
import {
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    TextField,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import { UnDelayLogoBlue } from 'styles/colors';

const CreateTicketModal = ({ open, handleClose }) => {
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [priority, setPriority] = useState('Medium'); // Default Priority
    const [status, setStatus] = useState('Open');
    const [dueBy, setDueBy] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [location, setLocation] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [teamViewerID, setTeamViewerID] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = () => {
        const newTicket = {
            subject,
            body: description,
            name,
            priority,
            status,
            dueBy,
            phoneNumber,
            location,
            departmentName,
            teamViewerID,
            email
        };
        alert('Ticket Created Successfully!');
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6" fontWeight="bold">IT Support: Create a New Ticket</Typography>
            </DialogTitle>

            <DialogContent>
                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Subject *</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={subject} 
                    onChange={(e) => setSubject(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Description *</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    multiline 
                    minRows={3} 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Name *</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Priority *</Typography>
                <FormControl fullWidth>
                    <Select
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                        variant="outlined"
                    >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Urgent">Urgent</MenuItem>
                    </Select>
                </FormControl>

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Status *</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={status} 
                    onChange={(e) => setStatus(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Due By</Typography>
                <TextField 
                    fullWidth 
                    type="datetime-local"
                    variant="outlined" 
                    value={dueBy} 
                    onChange={(e) => setDueBy(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Phone Number</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={phoneNumber} 
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Email *</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Location</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={location} 
                    onChange={(e) => setLocation(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Department</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={departmentName} 
                    onChange={(e) => setDepartmentName(e.target.value)}
                />

                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Team Viewer ID</Typography>
                <TextField 
                    fullWidth 
                    variant="outlined" 
                    value={teamViewerID} 
                    onChange={(e) => setTeamViewerID(e.target.value)}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} sx={{ color: UnDelayLogoBlue }}>Cancel</Button>
                <Button variant="contained" onClick={handleSubmit} 
                    sx={{ backgroundColor: UnDelayLogoBlue, color: '#fff' }}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateTicketModal;
