import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { auth } from "api/firebase";
import Image from 'assets/img/plane_1_1920.jpg';
import ForgotPassword from 'components/Auth/ForgotPassword';
import SignIn from 'components/Auth/SignIn';
import SignUp from 'components/Auth/SignUp';
import VerifyUser from 'components/Auth/VerifyUser';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  authContainer:{
    overflow: "auto",
    height: "100vh"
  }
}));

const Auth = () => {
  const classes = useStyles();
  const [authNav, changeAuth] = useState("SignIn");
  const [loadScreen, changeLoadingScreen] = useState(true)
  const [recapthaResponse, setRecapthaResponse] = useState(null)
  const history = useHistory()
 
  useEffect(() => {
    ReactGA.pageview('/auth');
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user) {
        //ReactGA.set({ userId: user.uid  }, [registeredAirportCode]);
        history.push('/dashboard')
      }else{
        changeLoadingScreen(false)
      }
    });
    return () => {
      unsubscribe();
    };
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  if(loadScreen){
    return <LoadingScreen/>
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline/>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid className={classes.authContainer} item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {
          authNav === "SignIn" ? <SignIn setRecapthaResponse = {setRecapthaResponse} changeAuth = {changeAuth}/> :
          authNav === "SignUp" ? <SignUp setRecapthaResponse = {setRecapthaResponse} changeAuth = {changeAuth}/> :
          authNav === "VerifyUser" ?  <VerifyUser recapthaResponse = {recapthaResponse}/> :
          <ForgotPassword changeAuth = {changeAuth}/> 
        }
      </Grid>
    </Grid>
  );
}

export default Auth