import React, { useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import StatisticCard from 'components/StatisticCard/StatisticCard';
import { lightGrey } from "styles/colors";
import UserActivityTable from 'components/UserActivityTable/UserActivityTable';

const UserActivityTab = () => {
  return (
    <Grid container spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h4" align="center" sx={{color: lightGrey}}>
            Recent Account Activity
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <UserActivityTable/>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h4" align="center" sx={{color: lightGrey}}>
            All Users Recent Activity
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <UserActivityTable allUsers={true}/>
      </Grid>
    </Grid>
  );
};

export default UserActivityTab;
