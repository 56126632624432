//https://mui.com/material-ui/material-icons/
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import SummarizeIcon from '@mui/icons-material/Summarize';

import RevenueManagementTab from "tabs/RevenueManagementTab";
import ParkingQueueTab from "tabs/ParkingQueueTab";
import ParkingReportsTab from "tabs/ParkingReportsTab";
import FeedbackSurveysTab from "tabs/FeedbackSurveysTab";
import InvoiceMgmtTab from "tabs/InvoiceMgmtTab";
import InvoiceReportsTab from "tabs/InvoiceReportsTab";
import TicketReportsTab from "tabs/TicketReportsTab";
import TicketsTab from "tabs/TicketsTab";
import UserActivityTab from "tabs/UserActivityTab";

const TabRoutes = [
  {
    path: '/revenue-management',
    sidebarName: 'Revenue Management',
    component: RevenueManagementTab,
    icon: AttachMoneyIcon
  },
  {
    path: '/parking-que',
    sidebarName: 'Parking Queue',
    component: ParkingQueueTab,
    icon: HourglassEmptyIcon
  },
  {
    path: '/parking-reports',
    sidebarName: 'Parking Reports',
    component: ParkingReportsTab,
    icon: AssessmentIcon
  },
  {
    path: '/feedback-surveys',
    sidebarName: 'Feedback Surveys',
    component: FeedbackSurveysTab,
    icon: FeedbackIcon
  },
  {
    path: '/invoice-management',
    sidebarName: 'Invoice Management',
    component: InvoiceMgmtTab,
    icon: ReceiptIcon
  },
  {
    path: '/invoice-reports',
    sidebarName: 'Invoice Reports',
    component: InvoiceReportsTab,
    icon: DescriptionIcon
  },
  {
    path: '/tickets',
    sidebarName: 'Tickets',
    component: TicketsTab,
    icon: ReceiptIcon
  },
  ,
  {
    path: '/ticket-reports',
    sidebarName: 'Ticket Reports',
    component: TicketReportsTab,
    icon: DescriptionIcon
  },
  {
    path: '/user-activity',
    sidebarName: 'User Activity',
    component: UserActivityTab,
    icon: SummarizeIcon
  },
];
  
export default TabRoutes;