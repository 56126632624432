import React, { useState } from 'react';
import { Box, Chip, Avatar, Typography, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { 
        field: 'subject', 
        headerName: 'SUBJECT', 
        width: 220,
        renderCell: (params) => (
            <Typography variant="body2" style={{ fontWeight: params.row.starred ? 'bold' : 'normal' }}>
                {params.value}
            </Typography>
        ),
        editable: true
    },
    { 
        field: 'name', 
        headerName: 'CONTACT', 
        width: 120,
        editable: true
    },
    { field: 'body', headerName: 'BODY', width: 230, editable: true },
    { 
        field: 'priority', 
        headerName: 'PRIORITY', 
        width: 120,
        renderCell: (params) => {
            const colors = { Low: 'success', Medium: 'warning', High: 'error', Urgent: 'error' };
            return <Chip label={params.value} color={colors[params.value]} size="small" />;
        }
    },
    { field: 'dueBy', headerName: 'DUE BY', width: 180, editable: true },
    { field: 'created', headerName: 'CREATED', width: 150, editable: true },
    { field: 'phoneNumber', headerName: 'PHONE NUMBER', width: 150, editable: true },
    { field: 'email', headerName: 'EMAIL', width: 150, editable: true },
    { field: 'location', headerName: 'LOCATION', width: 150, editable: true },
    { field: 'departmentName', headerName: 'DEPT', width: 150, editable: true },
    { field: 'teamViewerID', headerName: 'TEAM VIEWER ID', width: 150, editable: true },
];

const initialRows = [
    {
        id: 1,
        subject: '#355: Connection issue, Unable to access the project files',
        body: 'Aaron is experiencing a connection problem that prevents access to the NextGen project files. The issue might be related to network restrictions or missing permissions.',
        name: 'Aaron',
        priority: 'Medium',
        status: 'Resolved',
        dueBy: '03/31/24 - 08:29 PM',
        created: '5 days ago',
        phoneNumber: '404-555-1234',
        location: 'Cleveland Ave',
        departmentName: 'IT Support',
        teamViewerID: 'JC3TT',
        email: "aaron@smcmed.com"
    },
    {
        id: 2,
        subject: '#353: Maintenance Check',
        body: 'A routine maintenance check has been requested. It may involve inspecting system performance, clearing outdated logs, or ensuring all software is up to date.',
        name: 'Aaron',
        priority: 'Low',
        status: 'Resolved',
        dueBy: 'No SLA',
        created: '31 days ago',
        phoneNumber: '678-555-5678',
        location: 'Gresham',
        departmentName: 'Sales',
        teamViewerID: '069JY',
        email: "aaron@smcmed.com"
    },
    {
        id: 3,
        subject: '#346: Software Installation',
        body: 'Aaron needs assistance installing a new software application. The request may require verifying compatibility, obtaining the correct license, and completing the installation process.',
        name: 'Aaron',
        priority: 'Low',
        status: 'Open',
        dueBy: 'No SLA',
        created: '59 days ago',
        phoneNumber: '770-555-9012',
        location: 'Riverdale',
        departmentName: 'HR',
        teamViewerID: 'GXMH3',
        email: "aaron@smcmed.com"
    },
    {
        id: 4,
        subject: '#345: Install Software',
        body: 'A request has been made to install software on a company device. The installation may require administrator privileges or specific configuration settings.',
        name: 'Aaron',
        priority: 'Low',
        status: 'Pending',
        dueBy: 'No SLA',
        created: '59 days ago',
        phoneNumber: '470-555-3456',
        location: 'Cleveland Ave',
        departmentName: 'Finance',
        teamViewerID: 'QU58X',
        email: "aaron@smcmed.com"
    },
    {
        id: 5,
        subject: '#344: Browser Issue',
        body: 'Aaron is encountering difficulties using the web browser. The issue could involve slow performance, frequent crashes, or trouble loading specific sites.',
        name: 'Aaron',
        priority: 'Low',
        status: 'Closed',
        dueBy: 'No SLA',
        created: '59 days ago',
        phoneNumber: '678-555-7890',
        location: 'Gresham',
        departmentName: 'Operations',
        teamViewerID: '7ZN9W',
        email: "aaron@smcmed.com"
    },
    {
        id: 6,
        subject: '#343: Demo',
        body: 'Lara has requested a demonstration of a product or service. This may include showcasing key features, answering questions, and discussing potential use cases.',
        name: 'Lara',
        priority: 'Low',
        status: 'Open',
        dueBy: 'No SLA',
        created: '92 days ago',
        phoneNumber: '404-555-2345',
        location: 'Riverdale',
        departmentName: 'IT Support',
        teamViewerID: 'AB12C',
        email: "lara@smcmed.com"
    },
    {
        id: 7,
        subject: '#340: Need assistance with setup',
        body: 'Kay needs help setting up a system, device, or application. This could involve configuring settings, troubleshooting errors, or ensuring compatibility with existing tools.',
        name: 'Kay',
        priority: 'Low',
        status: 'Pending',
        dueBy: 'No SLA',
        created: '115 days ago',
        phoneNumber: '470-555-6789',
        location: 'Cleveland Ave',
        departmentName: 'Sales',
        teamViewerID: 'X1Y2Z',
        email: "kay@smcmed.com"
    },
    {
        id: 8,
        subject: '#339: Ticket from email',
        body: 'A support ticket was automatically created from an email inquiry. The issue described in the email needs to be reviewed and addressed.',
        name: 'Kay',
        priority: 'Low',
        status: 'Open',
        dueBy: 'No SLA',
        created: '118 days ago',
        phoneNumber: '770-555-4321',
        location: 'Gresham',
        departmentName: 'HR',
        teamViewerID: 'LMNOP',
        email: "kay@smcmed.com"
    },
    {
        id: 9,
        subject: '#338: Printer issue',
        body: 'Kay reported that the office printer is malfunctioning. The problem could range from paper jams to connectivity issues or low ink levels.',
        name: 'Kay',
        priority: 'Urgent',
        status: 'Open',
        dueBy: '12/12/23 - 03:30 AM',
        created: '118 days ago',
        phoneNumber: '404-555-8765',
        location: 'Riverdale',
        departmentName: 'Finance',
        teamViewerID: 'QWERT',
        email: "kay@smcmed.com"
    },
    {
        id: 10,
        subject: '#336: Ticket from Support Bot',
        body: 'A support bot has generated this ticket based on an automated system alert or a user request. Further investigation is needed to determine the exact issue.',
        name: 'Kay',
        priority: 'High',
        status: 'Open',
        dueBy: '12/13/23 - 03:30 AM',
        created: '118 days ago',
        phoneNumber: '678-555-2109',
        location: 'Cleveland Ave',
        departmentName: 'Operations',
        teamViewerID: 'ZXCVB',
        email: "kay@smcmed.com"
    }
];

const TicketTable = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [ticketTableList, setTicketTableList] = useState(initialRows);

    const handleCellEditCommit = (params) => {
        const { id, field, value } = params;

        setTicketTableList((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, [field]: value } : row
            )
        );
    };

    const filteredRows = ticketTableList.filter(row =>
        Object.values(row).some(value =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <Box sx={{ width: { xs: '100%', sm: 300 }, backgroundColor: '#FAFAFA', borderRadius: 2, p: 1 }}>
                    <TextField
                        label="Search..."
                        variant="outlined"
                        fullWidth
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Box>
            </Box>

            <Box sx={{ height: 600, width: '100%', backgroundColor: '#FAFAFA', borderRadius: 2 }}>
                <DataGrid 
                    rows={filteredRows} 
                    columns={columns} 
                    onCellEditCommit={handleCellEditCommit}
                    disableRowSelectionOnClick
                    sx={{
                        '& .MuiDataGrid-columnHeaders': { 
                            backgroundColor: '#F4F6F8', 
                            fontWeight: 'bold' 
                        },
                        '& .MuiDataGrid-cell': {
                            fontSize: '14px',
                        }
                    }}
                    pagination={false}
                    autoHeight={false}
                    hideFooter
                />
            </Box>
        </Box>
    );
}

export default TicketTable;