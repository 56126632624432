import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
} from "@mui/material";

const dummyData = [
  {
    agentName: "John Doe",
    ticketID: "T-1001",
    actionType: "Created Ticket",
    priorityLevel: "High",
    status: "Open",
    assignedTo: "Network Team",
    department: "IT Support",
    messageContent: "User reported internet outage.",
    timestamp: "03/07/2025 10:15 AM",
    resolutionTime: "N/A",
  },
  {
    agentName: "Jane Smith",
    ticketID: "T-1002",
    actionType: "Updated Priority",
    priorityLevel: "Urgent",
    status: "In Progress",
    assignedTo: "Security Team",
    department: "Cybersecurity",
    messageContent: "Adjusted ticket priority due to security breach.",
    timestamp: "03/07/2025 10:20 AM",
    resolutionTime: "N/A",
  },
  {
    agentName: "Alice Johnson",
    ticketID: "T-1003",
    actionType: "Sent Message",
    priorityLevel: "Low",
    status: "Resolved",
    assignedTo: "Help Desk",
    department: "Customer Support",
    messageContent: "Provided instructions to reset the password.",
    timestamp: "03/07/2025 10:30 AM",
    resolutionTime: "15 minutes",
  },
  {
    agentName: "Robert Brown",
    ticketID: "T-1004",
    actionType: "Closed Ticket",
    priorityLevel: "Medium",
    status: "Closed",
    assignedTo: "Tech Support",
    department: "Software Support",
    messageContent: "Issue with VPN resolved.",
    timestamp: "03/07/2025 11:00 AM",
    resolutionTime: "45 minutes",
  },
  {
    agentName: "John Doe",
    ticketID: "T-1001",
    actionType: "Created Ticket",
    priorityLevel: "High",
    status: "Open",
    assignedTo: "Network Team",
    department: "IT Support",
    messageContent: "User reported internet outage.",
    timestamp: "03/07/2025 10:15 AM",
    resolutionTime: "N/A",
  },
  {
    agentName: "Jane Smith",
    ticketID: "T-1002",
    actionType: "Updated Priority",
    priorityLevel: "Urgent",
    status: "In Progress",
    assignedTo: "Security Team",
    department: "Cybersecurity",
    messageContent: "Adjusted ticket priority due to security breach.",
    timestamp: "03/07/2025 10:20 AM",
    resolutionTime: "N/A",
  },
  {
    agentName: "Alice Johnson",
    ticketID: "T-1003",
    actionType: "Sent Message",
    priorityLevel: "Low",
    status: "Resolved",
    assignedTo: "Help Desk",
    department: "Customer Support",
    messageContent: "Provided instructions to reset the password.",
    timestamp: "03/07/2025 10:30 AM",
    resolutionTime: "15 minutes",
  },
  {
    agentName: "Robert Brown",
    ticketID: "T-1004",
    actionType: "Closed Ticket",
    priorityLevel: "Medium",
    status: "Closed",
    assignedTo: "Tech Support",
    department: "Software Support",
    messageContent: "Issue with VPN resolved.",
    timestamp: "03/07/2025 11:00 AM",
    resolutionTime: "45 minutes",
  },
];

const styles = {
  tableCell: {
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const UserActivityTable = ({allUsers}) => {
  const [activities, setActivities] = useState(dummyData);
  const [loading, setLoading] = useState(false);
  const tableContainerRef = useRef(null);
  const [hasMoreActivities, setHasMoreActivities] = useState(true);

  const handleScroll = useCallback(() => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100 && !loading && hasMoreActivities) {
        // Fetch more activities if needed (pagination logic)
      }
    }
  }, [loading, hasMoreActivities]);

  useEffect(() => {
    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  return (
    <Paper style={{ padding: 10 }}>
      <TableContainer ref={tableContainerRef} style={{ height: 450, overflowY: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="activity table">
          <TableHead>
            <TableRow>
                {[
                    allUsers ? "Agent Name" : "",
                    "Ticket ID",
                    "Action Type",
                    "Priority Level",
                    "Status",
                    "Assigned To",
                    "Department",
                    "Message Content",
                    "Timestamp",
                    "Resolution Time",
                ]
                    .filter(Boolean)
                    .map((heading) => (
                    <TableCell key={heading} sx={styles.tableCell}>
                        {heading}
                    </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map((row, i) => (
              <TableRow key={i}>
                {allUsers && (<TableCell sx={styles.tableCell}>{row.agentName}</TableCell>)}
                <TableCell sx={styles.tableCell}>{row.ticketID}</TableCell>
                <TableCell sx={styles.tableCell}>{row.actionType}</TableCell>
                <TableCell sx={styles.tableCell}>{row.priorityLevel}</TableCell>
                <TableCell sx={styles.tableCell}>{row.status}</TableCell>
                <TableCell sx={styles.tableCell}>{row.assignedTo}</TableCell>
                <TableCell sx={styles.tableCell}>{row.department}</TableCell>
                <TableCell sx={styles.tableCell}>{row.messageContent}</TableCell>
                <TableCell sx={styles.tableCell}>{row.timestamp}</TableCell>
                <TableCell sx={styles.tableCell}>{row.resolutionTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
    </Paper>
  );
};

export default UserActivityTable;
