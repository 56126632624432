import React from 'react';
import { Card, Typography, useTheme } from '@mui/material';

const StatisticCard = ({ smallCircles, title, textColor, count, backgroundColor, backgroundAfterColor }) => {
  const theme = useTheme(); 

  return (
    <Card
      sx={{
        position: 'relative',
        padding: 1,
        color: '#fff',
        overflow: 'hidden',
        backgroundColor: backgroundColor, 
        display: 'flex',
        justifyContent: 'space-between',
        '&:after': {
          content: '""',
          position: 'absolute',
          width: smallCircles ? 150 : 210,
          height:  smallCircles ? 150 : 210,
          backgroundColor: backgroundAfterColor,
          borderRadius: '50%',
          top: -85,
          right: -95,
          zIndex: 1,
          [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140,
          },
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          width: smallCircles ? 150 : 210,
          height: smallCircles ? 150 : 210,
          backgroundColor: backgroundAfterColor, // A subtle background circle (white or other)
          borderRadius: '50%',
          top: -125,
          right: -15,
          opacity: 0.5,
          zIndex: 0,
          [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70,
          },
        },
      }}
    >
      <div style={{ padding: 10 }}>
        <Typography
          sx={{
            color: textColor ? textColor : 'white',
            fontSize: '2.125rem',
            fontWeight: 500,
            mr: 1,
            mt: 1.75,
            mb: 0.75,
          }}
        >
          {count.toLocaleString()}
        </Typography>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 500,
            color: textColor ? textColor : 'white',
          }}
        >
          {title}
        </Typography>
      </div>
    </Card>
  );
};

export default StatisticCard;
