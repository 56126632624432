const filterFieldsBarReducer = (state = {
    flightDate: new Date(),
}, action) => {
    switch (action.type){
        case "updateFlightDate":
            return{
                ...state,
                flightDate: action.flightDate
            };
        default:
            return state
        }
}
  
export default filterFieldsBarReducer;